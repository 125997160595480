<template>
  <div class="d-flex align-center">
    <v-select
      v-model="selectedPreset"
      :items="fetchedList"
      item-value="_id"
      item-text="name"
      return-object
      @change="changePreset()"
      label="Filter presets"
      required
      solo-inverted
      flat
      dense
      small-chips
      hide-details
      class="mr-2 pb-0"
    />
    <v-btn
      @click="openNameModal(true)"
      small
      class="mr-1"
      elevation="0"
      :disabled="!selectedPreset"
    >
      <v-icon small class="mr-1">{{ Icons.edit }}</v-icon>
    </v-btn>
    <v-btn @click="checkOverwrite()" small class="mr-1" elevation="0">
      <v-icon small class="mr-1">{{ Icons.saveDisk }}</v-icon>
    </v-btn>
    <v-btn @click="openDeleteModal()" small class="mr-1" elevation="0" :disabled="!selectedPreset">
      <v-icon small class="mr-1">{{ Icons.delete }}</v-icon>
    </v-btn>
    <v-dialog v-model="nameModal" width="500" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <span v-if="isEditingName">{{ $t('model.FilterPreset.messages.editName') }}</span>
          <span v-else>{{ $t('model.FilterPreset.messages.newName') }}</span>
          <v-text-field
            label="Preset name"
            v-model="name"
            :rules="[
              (v) => !!v || $t('commons.rules.errorRequired'),
              (v) => (v && v.length <= 40) || $t('commons.rules.errorMaxLength', { count: 40 }),
            ]"
            required
            dense
            solo-inverted
            flat
            single-line
            clearable
            :prepend-inner-icon="Icons.text"
          ></v-text-field>
          <div v-if="isLoading" class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              size="20"
              width="1"
              color="$vuetify.theme.dark ? 'white' : 'black'"
            ></v-progress-circular>
          </div>
          <div class="red--text">{{ errorMessage }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeNameModal()">
            {{ $t('commons.operation.cancel') }}
          </v-btn>
          <v-btn color="primary" text @click="savePreset()" :disabled="!name || name.length > 40">
            {{ $t('commons.operation.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="overwriteModal" width="fit-content" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <div>{{ $t('model.FilterPreset.messages.overwriteModal') }}</div>
          <div v-if="isLoading" class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              size="20"
              width="1"
              color="$vuetify.theme.dark ? 'white' : 'black'"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="overwriteModal = false">
            {{ $t('commons.operation.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" text @click="(selectedPreset = null), openNameModal()">
            {{ $t('model.FilterPreset.buttons.new') }}
          </v-btn>
          <v-btn color="primary" text @click="savePreset()">
            {{ $t('model.FilterPreset.buttons.current') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteModal" width="300" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <div>{{ $t('model.FilterPreset.messages.deleteModal') }}</div>
          <div v-if="isLoading" class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              size="20"
              width="1"
              color="$vuetify.theme.dark ? 'white' : 'black'"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="deleteModal = false">
            {{ $t('commons.operation.no') }}
          </v-btn>
          <v-btn color="primary" text @click="deletePreset()">
            {{ $t('commons.operation.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="maxPresetModal" width="500" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <div>{{ $t('model.FilterPreset.messages.maxReached') }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="maxPresetModal = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HttpClientV2 from '../../store/HttpClientV2';
import ENTITIES from '../../store/constants/Entities';

const MAX_PRESETS = 10;

export default {
  name: 'filter-preset',
  data() {
    return {
      fetchedList: [],
      isLoading: false,
      selectedPreset: null,
      name: '',
      nameModal: false,
      overwriteModal: false,
      deleteModal: false,
      maxPresetModal: false,
      errorMessage: '',
      isEditingName: false,
    };
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetchList() {
      const params = {
        sortBy: 'name',
        descending: false,
        page: 0,
        limit: 999,
        disablePagination: 'false',
      };
      this.isLoading = true;
      await HttpClientV2.callFunctionV2('GET', ENTITIES.FILTER_PRESET, params)
        .then((result) => {
          this.fetchedList = result.resultSet;
        })
        .catch(() => {
          this.fetchedList = [];
        });
      this.isLoading = false;
    },
    async savePreset() {
      const restAction = this.selectedPreset ? 'PUT' : 'POST';
      const params = {
        type: this.type,
        settings: { ...this.filters, ...this.options },
      };
      delete params.settings.filters.dateRange;
      params._id = this.selectedPreset ? this.selectedPreset._id : undefined;
      params.name =
        this.selectedPreset && !this.isEditingName ? this.selectedPreset.name : this.name;
      this.isLoading = true;
      await HttpClientV2.callFunctionV2(restAction, ENTITIES.FILTER_PRESET, params)
        .then(() => {
          this.fetchList();
          this.reset();
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
      this.isLoading = false;
    },
    async deletePreset() {
      const params = {
        _id: this.selectedPreset._id,
      };
      this.isLoading = true;
      await HttpClientV2.callFunctionV2('DELETE', ENTITIES.FILTER_PRESET, params)
        .then(() => {
          this.fetchList();
          this.reset();
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        });
      this.isLoading = false;
    },
    changePreset() {
      this.$emit('changeFilterPreset', this.selectedPreset.settings);
    },
    checkOverwrite() {
      if (this.selectedPreset) {
        this.overwriteModal = true;
      } else {
        if (this.fetchedList.length > MAX_PRESETS - 1) {
          this.maxPresetModal = true;
          return;
        }
        this.name = '';
        this.errorMessage = '';
        this.nameModal = true;
      }
    },
    openNameModal(isEditing = false) {
      if (!!isEditing) this.isEditingName = true;
      if (this.fetchedList.length > MAX_PRESETS - 1) {
        this.overwriteModal = false;
        this.maxPresetModal = true;
        return;
      }
      this.name = '';
      this.errorMessage = '';
      this.overwriteModal = false;
      if (this.selectedPreset) {
        this.name = this.selectedPreset.name;
      }
      this.nameModal = true;
    },
    closeNameModal() {
      this.nameModal = false;
      this.isEditingName = false;
    },
    openDeleteModal() {
      this.deleteModal = true;
    },
    reset() {
      this.isLoading = false;
      this.overwriteModal = false;
      this.nameModal = false;
      this.deleteModal = false;
      this.selectedPreset = null;
      this.isEditingName = false;
      this.name = '';
    },
  },
  mounted() {
    this.fetchList();
  },
};
</script>
